import type { Profile } from "next-auth";
import type { AxiosError, AxiosRequestConfig } from "axios";
import * as https from "https";

import { BaseError, BaseResponse } from "~/interfaces/api";
import type { LoginCredentials, LoginResponse, SocialLoginResponse } from "~/interfaces/auth";
import { getErrorMessage } from "~/lib/error";
import { getBackendHeaders } from "~/lib/request";
import { ENDPOINTS } from "~/services/__endpoints";
import { baseApiService } from "~/services/baseApiService";
import { getUtmParamsFromCookies } from "~/shared/utils/utils";

import { getServerCookies } from "../cookies";

const httpsAgent = process.env.VERCEL_ENV === "development" ? new https.Agent({ rejectUnauthorized: false }) : null;

class Auth {
  constructor(private readonly api: typeof baseApiService) {}

  async login(credentials: LoginCredentials) {
    try {
      const res = await this.api.post<BaseResponse<LoginResponse>>(ENDPOINTS.auth.login, credentials, { httpsAgent });
      return res.data.user;
    } catch (error) {
      throw new Error(getErrorMessage(error as AxiosError<BaseError>));
    }
  }

  async googleLogin(idToken: string) {
    try {
      const cookies = await getServerCookies();
      const res = await this.api.post<BaseResponse<SocialLoginResponse>>(
        ENDPOINTS.auth.googleLogin,
        { idToken, ...getUtmParamsFromCookies(cookies) },
        { ...getBackendHeaders(), httpsAgent }
      );
      return res.data.user;
    } catch (error) {
      throw new Error(getErrorMessage(error as AxiosError<BaseError>));
    }
  }

  async socialLogin(profile: Profile) {
    try {
      const res = await this.api.post<BaseResponse<SocialLoginResponse>>(
        ENDPOINTS.auth.socialLogin,
        profile,
        getBackendHeaders()
      );
      return res.data.user;
    } catch (error) {
      throw new Error(getErrorMessage(error as AxiosError<BaseError>));
    }
  }

  async logout(refreshToken: unknown, options: AxiosRequestConfig = {}) {
    try {
      const { headers = {}, ...restOptions } = options;
      const res = await this.api.delete<string>(ENDPOINTS.auth.logout, {
        data: { refreshToken },
        httpsAgent,
        ...getBackendHeaders(headers),
        ...restOptions,
      });
      return res;
    } catch (error) {
      throw new Error(getErrorMessage(error as AxiosError<BaseError>));
    }
  }
}

export const auth = new Auth(baseApiService);
