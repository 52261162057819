import type { NextAuthOptions } from "next-auth";
// import TwitterProvider from "next-auth/providers/twitter";
// import LinkedInProvider from "next-auth/providers/linkedin";
import CredentialsProvider from "next-auth/providers/credentials";
import GoogleProvider from "next-auth/providers/google";

import { auth } from "~/lib/api/auth";
import logger from "~/lib/logger";

const useSecureCookies = process.env.NEXTAUTH_URL ? process.env.NEXTAUTH_URL.startsWith("https://") : false;
const cookiePrefix = useSecureCookies ? "__Secure-" : "";
const hostName = process.env.NEXTAUTH_URL ? new URL(process.env.NEXTAUTH_URL).hostname : "localhost";

export const authOptions: NextAuthOptions = {
  cookies: {
    sessionToken: {
      name: `${cookiePrefix}next-auth.session-token`,
      options: {
        httpOnly: true,
        sameSite: "lax",
        path: "/",
        domain: hostName == "localhost" ? hostName : "." + hostName.split(".").slice(-2).join("."), // add a . in front so that subdomains are included
        secure: useSecureCookies,
      },
    },
  },

  pages: {
    signIn: "/auth/login",
  },

  providers: [
    CredentialsProvider({
      name: "Credentials",
      credentials: {
        email: { label: "Email", type: "email", placeholder: "Email" },
        password: {
          label: "Password",
          type: "password",
          placeholder: "Password",
        },
      },
      async authorize(credentials) {
        if (!credentials || !credentials.email || !credentials?.password) {
          return null;
        }

        const authenticatedUser = await auth.login(credentials);

        if (authenticatedUser) {
          const user = {
            id: authenticatedUser._id,
            firstName: authenticatedUser.firstName,
            lastName: authenticatedUser.lastName,
            email: authenticatedUser.email,
            image: authenticatedUser.profileImageUrl,
            token: authenticatedUser.token,
            refreshToken: authenticatedUser.refreshToken,
            subscription: authenticatedUser.subscription,
          };

          return user;
        }

        return null;
      },
    }),

    GoogleProvider({
      clientId: process.env.GOOGLE_ID as string,
      clientSecret: process.env.GOOGLE_SECRET as string,
      profile({ sub, name, given_name, family_name, email, picture }) {
        return {
          id: sub,
          name: name,
          firstName: given_name,
          lastName: family_name,
          email: email,
          image: picture,
        };
      },
    }),
    // LinkedInProvider({
    //   clientId: process.env.LINKEDIN_ID as string,
    //   clientSecret: process.env.LINKEDIN_SECRET as string,
    // }),
    // TwitterProvider({
    //   clientId: process.env.TWITTER_ID as string,
    //   clientSecret: process.env.TWITTER_SECRET as string,
    //   version: "2.0",
    // }),
  ],

  callbacks: {
    async signIn({ user, account, profile }) {
      logger.debug("###### In SignIn callback ######");
      logger.debug(user);
      logger.debug(account);
      logger.debug(profile);

      return true;
    },

    async redirect({ url, baseUrl }) {
      logger.debug("###### In Redirect callback ######");
      logger.debug(url);
      logger.debug(baseUrl);

      // Allows relative callback URLs
      if (url.startsWith("/")) {
        return `${baseUrl}${url}`;
      }
      // Allows callback URLs on the same origin
      else if (new URL(url).origin === baseUrl) {
        return url;
      }

      return baseUrl;
    },

    async session({ session, token, user }) {
      logger.debug("###### In Session callback ######");
      logger.debug(session);
      logger.debug(user);
      logger.debug(token);

      session.accessToken = token.accessToken;
      session.refreshToken = token.refreshToken;
      session.id = token.id as string;

      if (token.isNewUser !== undefined) {
        session.isNewUser = token.isNewUser as boolean;
      }

      if (!session.user) {
        session.user = {};
      }

      if (token.firstName && token.lastName) {
        session.user.firstName = token.firstName as string;
        session.user.lastName = token.lastName as string;
        session.user.name = `${token.firstName} ${token.lastName}`;
      }

      if (token.image) {
        session.user.image = token.image as string;
      }

      session.isVerified = Boolean(token.isVerified);
      session.hasPaymentMethod = Boolean(token.hasPaymentMethod);

      return session;
    },

    async jwt({ token, user, account, profile, trigger, session }) {
      logger.debug("###### In JWT callback ######");
      logger.debug(token);
      logger.debug(user);
      logger.debug(account);
      logger.debug(profile);

      if (trigger === "update" && session) {
        return {
          ...token,
          accessToken: session.accessToken,
          refreshToken: session.refreshToken,
          firstName: session.user.firstName,
          lastName: session.user.lastName,
          image: session.user.image,
          isVerified: session.isVerified,
          hasPaymentMethod: session.hasPaymentMethod,
          isNewUser: session.isNewUser,
        };
      }

      if (account) {
        if (account.provider === "google" && account.id_token) {
          const authenticatedUser = await auth.googleLogin(account.id_token);

          if (authenticatedUser) {
            token.accessToken = authenticatedUser.token;
            token.refreshToken = authenticatedUser.refreshToken;
            token.id = authenticatedUser._id;
            token.isNewUser = authenticatedUser.isNewUser;
            token.firstName = authenticatedUser.firstName;
            token.lastName = authenticatedUser.lastName;
            token.image = authenticatedUser.profileImageUrl;
            token.isVerified = authenticatedUser.isVerified;
            token.hasPaymentMethod = Boolean(authenticatedUser.subscription);
          }
        } else if (account.type === "oauth") {
          if (account.provider && account.access_token && profile) {
            const authenticatedUser = await auth.socialLogin(profile);

            if (authenticatedUser) {
              token.accessToken = authenticatedUser.token;
              token.refreshToken = authenticatedUser.refreshToken;
              token.id = authenticatedUser._id;
            }
          }

          if (user && user.firstName && user.lastName) {
            token.firstName = user.firstName;
            token.lastName = user.lastName;
          }
        }

        if (account.type === "credentials" && user) {
          token.accessToken = user.token;
          token.refreshToken = user.refreshToken;
          token.id = user.id;
          token.firstName = user.firstName;
          token.lastName = user.lastName;
          token.image = user.image;
          token.isVerified = user.isVerified;
          token.hasPaymentMethod = Boolean(user.subscription);
        }
      }

      return token;
    },
  },

  events: {
    async signIn(message) {
      /* on successful sign in */
      logger.debug("###### In signIn event ######");
      logger.debug(message);
    },
    async signOut(message) {
      /* on signout */
      logger.debug("###### In signOut event ######");
      logger.debug(message);

      await auth.logout(message.token.refreshToken, {
        headers: {
          "x-access-token": message.token.accessToken as string,
        },
      });
    },
    async createUser(message) {
      /* user created */
      logger.debug("###### In createUser event ######");
      logger.debug(message);
    },
    async updateUser(message) {
      /* user updated - e.g. their email was verified */
      logger.debug("###### In updateUser event ######");
      logger.debug(message);
    },
    async linkAccount(message) {
      /* account (e.g. Twitter) linked to a user */
      logger.debug("###### In linkAccount event ######");
      logger.debug(message);
    },
    async session(message) {
      /* session is active */
      logger.debug("###### In session event ######");
      logger.debug(message);
    },
  },

  logger: {
    error(code, metadata) {
      logger.error(code, metadata);
    },
    warn(code) {
      logger.warn(code);
    },
    debug(code, metadata) {
      logger.debug(code, metadata);
    },
  },
};
