import { getSession, signOut } from "next-auth/react";
import axios from "axios";
import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import * as Sentry from "@sentry/nextjs";

import { analytics } from "~/app/analytics";
import logger from "~/lib/logger";
import { refreshToken, updateSession } from "~/lib/session";

import { API_KEY, BACKEND_API_KEY, BASE_URL } from "./__constants";
import { TRACKING_EVENTS } from "./__events";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestBody = any;

export class BaseApiService {
  constructor(protected api: AxiosInstance) {
    this.setupInterceptors();
  }

  protected setupInterceptors() {
    this.api.interceptors.request.use(this.handleRequest, this.handleRequestError);
    this.api.interceptors.response.use(this.handleResponse, this.handleResponseError.bind(this));
  }

  protected async handleRequest(request: InternalAxiosRequestConfig) {
    return request;
  }

  protected handleRequestError(error: AxiosError) {
    return Promise.reject(error);
  }

  protected handleResponse(response: AxiosResponse) {
    return response.data;
  }

  protected async handleResponseError(error: AxiosError) {
    if (error.response) {
      const session = await getSession();

      if (error?.response?.status === 401 && error?.config && session) {
        try {
          const prevRequest = error.config;
          // refresh token
          const res = await refreshToken(session.refreshToken);
          if (res) {
            // update session
            await updateSession({
              ...session,
              accessToken: res.token,
              refreshToken: res.refreshToken,
            });

            // set new access token to request header
            prevRequest.headers["x-access-token"] = `${res.token}`;
            // retry request
            return this.api.request(prevRequest);
          }
        } catch (error) {
          await analytics.track(TRACKING_EVENTS.userAuthTokenExpired, { username: session.user.email });
          await signOut();
        }
      }
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status, data, headers } = error.response;
      logger.error({ status, data, headers }, `Response error: ${error.message}`);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      logger.error(error.request, `Request error: ${error.message}`);
    } else if (!error.status) {
      // Possible network error or CORS issue
      logger.error(`Network error: ${error.message}`);
    } else {
      // Something happened in setting up the request that triggered an Error
      logger.error(`Setup error: ${error.message}`);
    }

    Sentry.captureException(error);
    return Promise.reject(error);
  }

  get<T>(url: string, options: AxiosRequestConfig = {}): Promise<T> {
    return this.api.get(url, options);
  }

  post<T>(url: string, body?: RequestBody, options: AxiosRequestConfig = {}): Promise<T> {
    return this.api.post(url, body, options);
  }

  postForm<T>(url: string, body: FormData, options: AxiosRequestConfig = {}): Promise<T> {
    return this.api.postForm(url, body, options);
  }

  patchForm<T>(url: string, body: FormData, options: AxiosRequestConfig = {}): Promise<T> {
    return this.api.patchForm(url, body, options);
  }

  put<T>(url: string, body: RequestBody, options: AxiosRequestConfig = {}): Promise<T> {
    return this.api.put(url, body, options);
  }

  patch<T>(url: string, body?: RequestBody, options: AxiosRequestConfig = {}): Promise<T> {
    return this.api.patch(url, body, options);
  }

  delete<T>(url: string, options: AxiosRequestConfig = {}): Promise<T> {
    return this.api.delete(url, options);
  }
}

export const baseApiService = new BaseApiService(
  axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": API_KEY,
      ...(BACKEND_API_KEY && { "x-backend-api-key": BACKEND_API_KEY }),
    },
  })
);
