export const getBackendHeaders = (additionalHeaders = {}) => {
  if (process.env.BACKEND_API_KEY) {
    return {
      headers: {
        "x-backend-api-key": process.env.BACKEND_API_KEY,
        ...additionalHeaders,
      },
    };
  }

  return { headers: additionalHeaders };
};
