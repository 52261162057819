import type { AxiosError } from "axios";

import type { BaseError } from "~/interfaces/api";

export const getErrorMessage = (error: AxiosError<BaseError>) => {
  const message =
    error.response?.data.message || error.response?.data.error || error.message;

  return message || "Something went wrong";
};
