import { redirect } from "next/navigation";
import { getServerSession, type Session } from "next-auth";
import { getCsrfToken } from "next-auth/react";
import axios, { type AxiosError } from "axios";

import { authOptions } from "~/app/api/auth/[...nextauth]/options";
import type { BaseResponse } from "~/interfaces/api";
import type { RefreshTokenResponse } from "~/interfaces/auth";
import { API_KEY, BASE_URL } from "~/services/__constants";
import { ENDPOINTS } from "~/services/__endpoints";

export const refreshToken = async (refreshToken: unknown) => {
  try {
    const res = await axios.post<BaseResponse<RefreshTokenResponse>>(
      `${BASE_URL}${ENDPOINTS.auth.tokens}`,
      { refreshToken },
      { headers: { "x-api-key": API_KEY, "Content-Type": "application/json" } }
    );
    return res.data.data.tokens;
  } catch (error) {
    throw new Error((error as AxiosError).message);
  }
};

export const updateSession = async (session: Session) => {
  const csrfToken = await getCsrfToken();

  await axios.post(
    "/api/auth/session",
    { csrfToken, data: session },
    { headers: { "Content-Type": "application/json" } }
  );
};

export const redirectAuthenticated = async () => {
  const session = await getServerSession(authOptions);
  if (session) {
    redirect("/");
  }
};
