const auth = "auth";
const users = "users";
const brands = "brands";
const campaigns = "campaigns";
const dataExtraction = "data-extraction";
const config = "config";
const payments = "payments";
const formats = "formats";
const strategies = "strategies";
const creatives = "creatives";

export const ENDPOINTS = {
  auth: {
    login: `/${auth}/login`,
    googleLogin: `/${auth}/google-login`,
    socialLogin: `/${auth}/social-login`,
    logout: `/${auth}/logout`,
    tokens: `/${auth}/tokens`,
  },
  users: {
    root: `/${users}`,
    credits: `/${users}/credits`,
    forgotPassword: (email: string) => `/${users}/password-forgotten/${email}`,
    updatePassword: (userId: string) => `/${users}/${userId}/password`,
    passwordResetVerification: (userId: string, passwordResetString: string) =>
      `/${users}/${userId}/password-reset-verification/${passwordResetString}`,
    verifyEmail: (userId: string, emailVerificationString: string) =>
      `/${users}/${userId}/verification/${emailVerificationString}`,
  },
  brands: {
    root: `/${brands}`,
    checkLimitReached: `/${brands}/check-limit-reached`,
    byId: (id: string) => `/${brands}/${id}`,
    campaigns: (id: string) => `/${brands}/${id}/campaigns`,
  },
  campaigns: {
    root: `/${campaigns}`,
    byId: (id: string) => `/${campaigns}/${id}`,
    count: `/${campaigns}/count`,
    searchImages: `/${campaigns}/search-images`,
    creatives: (id: string) => `/${campaigns}/${id}/creatives`,
    generateCopy: (id: string) => `/${campaigns}/${id}/generate-copy`,
    generateImageText: `/${campaigns}/generate-image-text`,
    generateSearchQuery: `/${campaigns}/generate-image-search-query`,
    copy: (id: string) => `/${campaigns}/${id}/copy`,
  },
  creatives: {
    root: `/${creatives}`,
    byId: (id: string) => `/${creatives}/${id}`,
    download: (id: string) => `/${creatives}/${id}/download`,
  },
  dataExtraction: {
    brandInfo: (url: string) => `/${dataExtraction}/brand-information/${url}`,
    metaInfo: `/${dataExtraction}/meta-information`,
  },
  config: {
    languages: `/${config}/languages`,
  },
  payments: {
    createCheckoutSession: `/${payments}/checkout-session`,
    createCustomerSession: `/${payments}/customer-session`,
    getCheckoutSession: (sessionId: string) => `/${payments}/checkout-session/${sessionId}`,
    plans: `/${payments}/plans`,
    changePlan: `/${payments}/change-plan`,
  },
  formats: {
    root: `/${formats}`,
  },
  strategies: {
    root: `/${strategies}`,
  },
};
