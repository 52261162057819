import pino from "pino";

const logger = pino({
  name: "web",
  timestamp: () => `,"time":"${new Date(Date.now()).toISOString()}"`,
  browser: { asObject: true },
  level: process.env.NEXT_PUBLIC_LOG_LEVEL || "info",
});

export default logger;
