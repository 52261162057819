import { getServerSession } from "next-auth";
import { getSession } from "next-auth/react";
import axios, { InternalAxiosRequestConfig } from "axios";

import { authOptions } from "~/app/api/auth/[...nextauth]/options";

import { API_KEY, BACKEND_API_KEY, BASE_URL } from "./__constants";
import { BaseApiService } from "./baseApiService";

export class ApiService extends BaseApiService {
  protected async handleRequest(request: InternalAxiosRequestConfig) {
    const isBrowser = typeof window !== "undefined";
    const session = isBrowser ? await getSession() : await getServerSession(authOptions);

    if (session?.accessToken) {
      request.headers["x-access-token"] = `${session.accessToken}`;
    }

    return request;
  }
}

export const apiService = new ApiService(
  axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": API_KEY,
      ...(BACKEND_API_KEY && { "x-backend-api-key": BACKEND_API_KEY }),
    },
  })
);
